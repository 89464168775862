export const REGIONDATA = {
    "regions": [
        { "region": "Arusha","geometry": null },
        { "region": "Dar es Salaam","geometry": null },
        { "region": "Dodoma","geometry": null },
        { "region": "Geita" , "geometry": null },
        { "region": "Iringa","geometry": null },
        { "region": "Kagera","geometry": null },
        { "region": "Katavi","geometry": null },
        { "region": "Kigoma","geometry": null },
        { "region": "Kilimanjaro","geometry": null},
        { "region": "Lindi","geometry": null },
        { "region": "Manyara","geometry": null },
        { "region": "Mara","geometry": null },
        { "region": "Mbeya","geometry": null },
        { "region": "Morogoro","geometry": null },
        { "region": "Mtwara","geometry": null },
        { "region": "Mwanza","geometry": null },
        { "region": "Njombe","geometry": null },
        { "region": "Pemba Kaskazini","geometry": null},
        { "region": "Pemba Kusini","geometry": null},
        { "region": "Pwani","geometry": null },
        { "region": "Rukwa","geometry": null },
        { "region": "Ruvuma","geometry": null },
        { "region": "Shinyanga","geometry": null},
        { "region": "Simiyu","geometry": null },
        { "region": "Singida","geometry": null },
        { "region": "Songwe","geometry": null },
        { "region": "Tabora","geometry": null },
        { "region": "Tanga","geometry": null },
        { "region": "Unguja Kaskazini","geometry": null},
        { "region": "Unguja Mjini Magharibi","geometry": null },
        { "region": "Unguja Kusini","geometry": null }
    ]  
}