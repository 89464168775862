import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by"></span>
    <div class="socials">
      <a href="https://www.fundismart.co.tz/" target="_blank">FundiSmart &copy; {{year}}</a>
    </div>
  `,
})
export class FooterComponent implements OnInit{
  public year;
  ngOnInit(): void {
    this.updateYear();
  }
  updateYear(){
    this.year = new Date().getFullYear();
  }
}