import { Component, Input ,OnInit,OnDestroy} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SharedService } from '../../../../services/shared.service';
import { MenuService } from '../../../../services/menu.service';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class ShowcaseDialogComponent implements OnInit,OnDestroy {
  @Input() message_type: string;
  @Input() message: string;
  @Input() menu: string;
  @Input() show_text_input:boolean;
  @Input() show_cancel_btn:boolean;
  @Input() show_submit_btn:boolean;

  private ussd_inputs:any[];
  private ussd_text:string;
  private ussd_level:number;
  
  private services:string;
  private serviceMsg:any;
  private exitMsg:any;

  private timeout:any;

  constructor(
    private menuService:MenuService,
    private sharedService:SharedService,
    protected ref: NbDialogRef<ShowcaseDialogComponent>
  ) {}

  ngOnInit(): void {
    this.ussd_inputs = [];
    this.loadUssdServiceMenu();
    this.loadMenuMessages();
  }
  loadUssdServiceMenu(){
    this.menuService.getUssdServiceMenu()
    .subscribe(data=>{
      this.services = data.menu;
    })
  }
  submit(){
    let inputEl:any = document.getElementById('input') as HTMLElement;
    if(inputEl){
      let value = inputEl.value;
      this.addToUserInputs(value);
      this.ref.close();
      this.sharedService.dialog = false;
      this.sharedService.clearPreviewData();
      this.handleUSSDRequest();
    }
  }
  dismiss() {
    this.ref.close();
    this.sharedService.dialog = false;
    this.sharedService.clearPreviewData();
    this.sharedService.clearPreviewInputs();
  }
  handleUSSDRequest(){
    switch(this.message_type){
      case 'welcome_msg':
        this.welcomeMenu();
        break;
      case 'service_msg':        
        let inputs = this.sharedService.getPreviewInputs();
        let menu_level = parseInt(inputs.length);
        if(inputs){
          let exit_code = inputs.find(each=>each === '00'); 
          let back_code = inputs.find(each=>each === '99');
          if(!exit_code){
            if(!back_code){
              let menu_position = inputs[0];
              if(menu_level == 1){              
                this.showServiceSubmenu(menu_position);
              }else if(menu_level == 2){
                let submenu_position = inputs[1];
                this.submitRequest(menu_position,submenu_position);         
              }
            }else{
              this.sharedService.clearPreviewInputs();
              this.showServiceMenu();
            }
          }else{
            this.runUssdCode(this.exitMsg.message,'',false);
          }
        }
        break;  
    }
  }
  //welcome menu
  private welcomeMenu(){
    let input = parseInt(this.ussd_inputs[0]);
    if(input == 1){
      this.showRegistrationMenu();
    }else if(input == 2){
      this.showServiceList();
    }else{
       this.runUssdCode('','Uchaguzi sio sahihi jaribu tena.',false);
    }
  }
  private showRegistrationMenu(){
    let inputs = this.sharedService.getPreviewInputs();
    this.ussd_level =  inputs.length;
    switch(this.ussd_level){
      case 1:
        this.ussd_text = "Jina la Kwanza.";
        this.runUssdCode('',this.ussd_text,true);
        break;
      case 2:
        this.ussd_text = "Jina la Mwisho.";
        this.runUssdCode('',this.ussd_text,true);
        break;
      case 3:
        this.ussd_text = "Jinsia. (Kiume/Kike)";
        this.runUssdCode('',this.ussd_text,true);
        break; 
      case 4:      
        this.ussd_text = "Aina ya Ufundi.";
        this.runUssdCode('',this.ussd_text,true);
        break; 
      case 5:
        this.ussd_text = "Umesomea ? (Ndio/Hapana).";
        this.runUssdCode('',this.ussd_text,true);
        break; 
      case 6:
        this.ussd_text = "Jina la Mkoa.";
        this.runUssdCode('',this.ussd_text,true);
        break;         
      case 7:       
        this.ussd_text = "Jina la Wilaya.";
        this.runUssdCode('',this.ussd_text,true);
        break;
      case 8:
        this.ussd_text = "Jina la Kata/Shehia";
        this.runUssdCode('',this.ussd_text,true);
        break;
      case 9:
        this.ussd_text = "Jina la Mtaa/Kijiji.";
        this.runUssdCode('',this.ussd_text,true);
        break;    
      case 10:
        let end_msg = 'Asante kwa kujiunga na Fundi Smart.\n';
            end_msg += "Utapokea ujumbe mfupi hivi punde kuthibitisha usajiri wako.";
       this.runUssdCode('',end_msg,false);                
    } 
  }
  private showServiceList(){
    let message = "Huduma za FundiSmart\n";
    let list = "";
    if(this.services.length != 0){
      this.runUssdCode(message,this.services,false);
    }    
  }
  //service menu
  private showServiceMenu(){
    let message = this.serviceMsg.message+"\n";
    if(this.services.length != 0){
      this.runUssdCode(message,this.services,true);
    }
  }
  private showServiceSubmenu(menu_position){
    let loaderDiv:any = document.getElementById('loader-div') as HTMLElement;
    this.showLoader(loaderDiv);
    this.menuService.getUssdServiceSubmenu(menu_position)
    .subscribe(data=>{
     if(data){
       this.runUssdCode('',data.submenu,true);
     }
    })
  }
  private submitRequest(menu_position,submenu_position){
    let loaderDiv:any = document.getElementById('loader-div') as HTMLElement;
    this.showLoader(loaderDiv);
    this.menuService.getUssdSubmenu(menu_position,submenu_position)
    .subscribe(data=>{
       if(data){
        let submenu = data.submenu;
        this.runUssdCode('',submenu.notification,false);
       }
    })
  }
  //handle data loading
  loadMenuMessages(){
    this.menuService.getMenuMessages()
    .subscribe((data)=>{
      if(data){
        this.serviceMsg = this.filterMsgByType('service_msg',data.messages);
        this.exitMsg = this.filterMsgByType('exit_msg',data.messages)
      } 
    },(error)=>{
      // this.loader = false;
    })
  }
  //tools method
  private showLoader(loader){
    if(!this.sharedService.dialog){
      if(loader.classList.contains('hide')){
        loader.classList.remove('hide');
        loader.classList.add('show');
      }
    }
  }
  private hideLoader(loader){
    if(loader.classList.contains('show')){
      loader.classList.remove('show');
      loader.classList.add('hide');
    }
  }
  private addToUserInputs(value){
    let inputs = this.sharedService.getPreviewInputs();
    if(inputs){
      this.ussd_inputs = [...inputs];
      this.ussd_inputs.push(value);
      this.sharedService.storePreviewInputs(this.ussd_inputs);
    }else{
      this.ussd_inputs.push(value);
      this.sharedService.storePreviewInputs(this.ussd_inputs);
    }
  }
  private runUssdCode(msg,menu,ussd_proceed:boolean){
    let data = {
      message_type:this.message_type,
      message:msg,
      menu:menu,
      show_cancel_btn:true,
      show_text_input:ussd_proceed ? true : false,
      show_submit_btn:ussd_proceed ? true : false
    }
    let loaderDiv:any = document.getElementById('loader-div') as HTMLElement;
    if(loaderDiv){
      this.showLoader(loaderDiv);
      if(!this.sharedService.dialog){
        this.timeout = setTimeout(()=>this.sharedService.showPopup(loaderDiv,data),1000)
      }
    }
  }
  private  filterMsgByType(type,messages){
    if(messages){
      let msg = messages.find(each=>each.type === type);
      if(msg){
        return msg;
      }
    }
  }
  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}