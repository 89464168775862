import { Injectable } from '@angular/core';
import { CanActivate,CanDeactivate,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate{
  private leave:boolean;
  constructor(
    private auth:AuthService,
    private sharedService:SharedService,
    private router:Router) { }
  canActivate():boolean{
    if(this.auth.isLoggedIn()){
      return true;
    }
    this.router.navigate(['auth']);
  }
}