import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ShowcaseDialogComponent } from '../pages/modal-overlays/dialog/showcase-dialog/showcase-dialog.component';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
  NbToastrConfig,
  } from '@nebular/theme';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // public api_url = 'http://127.0.0.1:8000/api';//local
  public api_url = 'http://fundismartapp.co.tz/fundismart_api/public/api';//live

  // public app_url = 'http://localhost:4200';//local
  public app_url = 'http://fundismartapp.co.tz';//live

  public menuRefreshSubject:Subject<any> = new Subject();

  //dialog
  public dialog:any;
  private timeInterval:any;
  private previewData:any;
  private timeout:any;
  
  //toast attributes
  config: NbToastrConfig;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;

  constructor(
    private dialogService:NbDialogService,  
    private toastrService: NbToastrService,
  ) { }
  //preview data
  storePreviewData(data:any){
    data = JSON.stringify(data)
    if(localStorage){
      localStorage.setItem('preview_data',data);
    }
  }
  getPreviewData():any{
    if(localStorage){
      let data = localStorage.getItem('preview_data');
      return JSON.parse(data);
   }
  }
  clearPreviewData():any{
   if(localStorage){
      localStorage.removeItem('preview_data');
   }
  }
  //refresh side menu
  getMenuRefreshState():Subject<boolean>{
    return this.menuRefreshSubject;
  }
  setMenuRefreshState(state){
    this.menuRefreshSubject.next(state);
  }
  //preview user inputs
  storePreviewInputs(data:any){
    data = JSON.stringify(data)
    if(localStorage){
      localStorage.setItem('preview_inputs',data);
    }
  }
  getPreviewInputs():any{
    if(localStorage){
      let data = localStorage.getItem('preview_inputs');
      return JSON.parse(data);
   }
  }
  clearPreviewInputs():any{
   if(localStorage){
      localStorage.removeItem('preview_inputs');
   }
  }
  //locations
  // getRegions(){
  //   return tanzaniageodata.regions();
  // }
  //dialog
  public showPopup(loader,data){
    this.hideLoader(loader);
    if(!this.dialog){      
      this.dialog = this.dialogService.open(
      ShowcaseDialogComponent ,
      {
        context: {
          message_type:data.message_type,
          message:data.message,
          menu:data.menu,
          show_text_input:data.show_text_input,
          show_cancel_btn:data.show_cancel_btn,
          show_submit_btn:data.show_submit_btn,
        },
        closeOnBackdropClick: false,
      });
      clearTimeout(this.timeout);
    }
  }
  private showLoader(loader){
    if(!this.dialog){
      if(loader.classList.contains('hide')){
        loader.classList.remove('hide');
        loader.classList.add('show');
      }
    }
  }
  private hideLoader(loader){
    if(loader.classList.contains('show')){
      loader.classList.remove('show');
      loader.classList.add('hide');
    }
  }
  //toast
  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `${title}` : '';
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
