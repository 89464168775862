import { Injectable } from '@angular/core';
import {Observable,of as ObservableOf} from 'rxjs';
import {HttpClient } from '@angular/common/http';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor( private http:HttpClient,private sharedService:SharedService) {}
  //menu messages
  getMenuMessages():Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/menu/messages`);
  }
  updateMenuMessages(messages:any):Observable<any>{
    return this.http.post(`${this.sharedService.api_url}/menu/messages`,messages);
  }
  // service main menu
  getServiceMenu():Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/service/menu`);
  }
  getServiceSingleMenu(menuId:any):Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/service/menu/${menuId}`);
  }
  getActiveServiceMenu():Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/service/active/menu`);
  }
  addServiceMenu(data:any):Observable<any>{
    return this.http.post(`${this.sharedService.api_url}/service/menu`,data);
  }
  updateServiceMenu(menu:any,data:any):Observable<any>{
    return this.http.put(`${this.sharedService.api_url}/service/menu/${menu}`,data);
  }
  removeServiceMenu(menu:any):Observable<any>{
    return this.http.delete(`${this.sharedService.api_url}/service/menu/${menu}`);
  }
  //service submenu
  getServiceSubmenu():Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/service/submenu`);
  }
  filterByMenuId(menuId:any):Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/service/submenu/filter/${menuId}`);
  }
  addServiceSubmenu(data:any):Observable<any>{
    return this.http.post(`${this.sharedService.api_url}/service/submenu`,data);
  }
  updateServiceSubmenu(menu:any,data:any):Observable<any>{
    return this.http.put(`${this.sharedService.api_url}/service/submenu/${menu}`,data);
  }
  removeServiceSubmenu(menu:any):Observable<any>{
    return this.http.delete(`${this.sharedService.api_url}/service/submenu/${menu}`);
  }
  //service ussd menu
  getUssdServiceMenu():Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/ussd/service/menu`);
  }
  getUssdServiceSubmenu(menuPosition:string):Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/ussd/service/submenu/${menuPosition}`);
  }
  getUssdSubmenu(menuPosition:string,submenuPosition:string):Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/ussd/service/returnsubmenu/${menuPosition}/${submenuPosition}`);
  }
  //menu access control
  addAccessList(data:any):Observable<any>{
    return this.http.post(`${this.sharedService.api_url}/menu/access/list`,data);
  }
  updateAccessList(menuAccessList:any,data:any):Observable<any>{
    return this.http.put(`${this.sharedService.api_url}/menu/access/list/${menuAccessList}`,data);
  }
  getMenuAccessList():Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/menu/access/list`);
  }
  clearMenuAccessList(id:any):Observable<any>{
    return this.http.delete(`${this.sharedService.api_url}/menu/access/list/${id}`);
  }
  //ussd request
  getUssdRequets(menuId:any,filters:any):Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/ussd/service/requests/${menuId}?page=${filters.page}&per_page=${filters.per_page}`);
  }
}
