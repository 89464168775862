export const DISTRICTDATA = {
    "districts": [
        { "region": "Arusha Region", "District": "Meru District","geometry": null},
        { "region": "Arusha Region", "District": "Arusha City", "geometry": null },
        { "region": "Arusha Region", "District": "Arusha District", "geometry": null },
        { "region": "Arusha Region", "District": "Karatu District", "geometry": null },
        { "region": "Arusha Region", "District": "Longido District", "geometry": null },
        { "region": "Arusha Region", "District": "Monduli District", "geometry": null },
        { "region": "Arusha Region", "District": "Ngorongoro District","geometry": null },
        { "region": "Dar es Salaam Region", "District": "Ilala Municipal", "geometry": null },
        { "region": "Dar es Salaam Region", "District": "Kinondoni Municipal", "geometry": null },
        {  "region": "Dar es Salaam Region", "District": "Temeke Municipal", "geometry": null },
        { "region": "Dar es Salaam Region", "District": "Kigamboni Municipal", "geometry": null },
        { "region": "Dar es Salaam Region", "District": "Ubungo Municipal", "geometry": null },
        { "region": "Dodoma Region", "District": "Bahi District" , "geometry": null },
        {  "region": "Dodoma Region", "District": "Chamwino District", "geometry": null },
        { "region": "Dodoma Region", "District": "Chemba District" , "geometry": null },
        { "region": "Dodoma Region", "District": "Dodoma Municipal" , "geometry": null },
        { "region": "Dodoma Region", "District": "Kondoa District" , "geometry": null },
        { "region": "Dodoma Region", "District": "Kongwa District" , "geometry": null },
        { "region": "Dodoma Region", "District": "Mpwapwa District" , "geometry": null },
        { "region": "Geita Region", "District": "Bukombe District" , "geometry": null },
        {  "region": "Geita Region", "District": "Chato District" , "geometry": null },
        {  "region": "Geita Region", "District": "Geita Town" , "geometry": null },
        { "region": "Geita Region", "District": "Mbogwe District" , "geometry": null },
        { "region": "Geita Region", "District": "Nyang'hwale District" , "geometry": null },
        {  "region": "Iringa Region", "District": "Iringa District" , "geometry": null },
        {  "region": "Iringa Region", "District": "Iringa Municipal" , "geometry": null },
        {  "region": "Iringa Region", "District": "Kilolo District" , "geometry": null },
        { "region": "Iringa Region", "District": "Mafinga Town" , "geometry": null },
        { "region": "Iringa Region", "District": "Mufindi District" , "geometry": null },
        {  "region": "Kagera Region", "District": "Biharamulo District" , "geometry": null },
        {  "region": "Kagera Region", "District": "Bukoba District" , "geometry": null },
        { "region": "Kagera Region", "District": "Bukoba Municipal" , "geometry": null },
        { "region": "Kagera Region", "District": "Karagwe District" , "geometry": null },
        { "region": "Kagera Region", "District": "Kyerwa District" , "geometry": null },
        { "region": "Kagera Region", "District": "Missenyi District" , "geometry": null },
        { "region": "Kagera Region", "District": "Muleba District", "geometry": null },
        { "region": "Kagera Region", "District": "Ngara District" , "geometry": null },
        { "region": "Katavi Region", "District": "Mlele District" , "geometry": null },
        { "region": "Katavi Region", "District": "Mpanda District" , "geometry": null },
        {"region": "Katavi Region", "District": "Mpanda Town" , "geometry": null },
        {"region": "Kigoma Region", "District": "Buhigwe District" , "geometry": null },
        {"region": "Kigoma Region", "District": "Kakonko District" , "geometry": null },
        {"region": "Kigoma Region", "District": "Kasulu District" , "geometry": null },
        { "region": "Kigoma Region", "District": "Kasulu Town" , "geometry": null },
        { "region": "Kigoma Region", "District": "Kibondo District" , "geometry": null },
        { "region": "Kigoma Region", "District": "Kigoma District" , "geometry": null },
        { "region": "Kigoma Region", "District": "Kigoma-Ujiji Municipal" , "geometry": null },
        { "region": "Kigoma Region", "District": "Uvinza District" , "geometry": null },
        { "region": "Kilimanjaro Region", "District": "Hai District" , "geometry": null },
        {  "region": "Kilimanjaro Region", "District": "Moshi District" , "geometry": null },
        { "region": "Kilimanjaro Region", "District": "Moshi Municipal" , "geometry": null },
        { "region": "Kilimanjaro Region", "District": "Mwanga District" , "geometry": null },
        { "region": "Kilimanjaro Region", "District": "Rombo District" , "geometry": null },
        { "region": "Kilimanjaro Region", "District": "Same District" , "geometry": null },
        { "region": "Kilimanjaro Region", "District": "Siha District" , "geometry": null },
        { "region": "Pemba Kusini Region", "District": "Chake Chake District" , "geometry": null },
        { "region": "Pemba Kusini Region", "District": "Mkoani District" , "geometry": null },
        { "region": "Unguja Kusini Region", "District": "Kati District" , "geometry": null },
        { "region": "Unguja Kusini Region", "District": "Kusini District" , "geometry": null },
        {  "region": "Lindi Region", "District": "Kilwa District" , "geometry": null },
        { "region": "Lindi Region", "District": "Lindi District" , "geometry": null },
        { "region": "Lindi Region", "District": "Lindi Municipal" , "geometry": null },
        { "region": "Lindi Region", "District": "Liwale District" , "geometry": null },
        {  "region": "Lindi Region", "District": "Nachingwea District" , "geometry": null },
        { "region": "Lindi Region", "District": "Ruangwa District" , "geometry": null },
        { "region": "Manyara Region", "District": "Babati Town" , "geometry": null },
        { "region": "Manyara Region", "District": "Babati District" , "geometry": null },
        { "region": "Manyara Region", "District": "Hanang District" , "geometry": null },
        { "region": "Manyara Region", "District": "Kiteto District" , "geometry": null },
        { "region": "Manyara Region", "District": "Mbulu District", "geometry": null },
        { "region": "Manyara Region", "District": "Simanjiro District","geometry": null },
        { "region": "Mara Region", "District": "Bunda District","geometry": null },
        { "region": "Mara Region", "District": "Butiama District","geometry": null },
        { "region": "Mara Region", "District": "Musoma District","geometry": null },
        { "region": "Mara Region", "District": "Musoma Municipal","geometry": null },
        { "region": "Mara Region", "District": "Rorya District","geometry": null },
        { "region": "Mara Region", "District": "Serengeti District","geometry": null },
        { "region": "Mara Region", "District": "Tarime District","geometry": null },
        { "region": "Mbeya Region", "District": "Busokelo District","geometry": null },
        { "region": "Mbeya Region", "District": "Chunya District","geometry": null },
        { "region": "Mbeya Region", "District": "Kyela District","geometry": null },
        { "region": "Mbeya Region", "District": "Mbarali District","geometry": null },
        { "region": "Mbeya Region", "District": "Mbeya City","geometry": null },
        { "region": "Mbeya Region", "District": "Mbeya District","geometry": null },
        { "region": "Mbeya Region", "District": "Rungwe","geometry": null },
        { "region": "Unguja Mjini Magharibi Region","District": "Magharibi District","geometry": null },
        { "region": "Unguja Mjini Magharibi Region", "District": "Mjini District","geometry": null },
        { "region": "Morogoro Region", "District": "Gairo District","geometry": null },
        {"region": "Morogoro Region", "District": "Kilombero District","geometry": null },
        { "region": "Morogoro Region", "District": "Kilosa District","geometry": null },
        { "region": "Morogoro Region", "District": "Morogoro District","geometry": null },
        { "region": "Morogoro Region", "District": "Morogoro Municipal","geometry": null },
        { "region": "Morogoro Region", "District": "Mvomero District","geometry": null },
        { "region": "Morogoro Region", "District": "Ulanga District","geometry": null },
        { "region": "Morogoro Region", "District": "Malinyi District","geometry": null },
        { "region": "Morogoro Region", "District": "Ifakara Township","geometry": null },
        { "region": "Mtwara Region", "District": "Masasi District","geometry": null },
        { "region": "Mtwara Region", "District": "Masasi Town","geometry": null },
        { "region": "Mtwara Region", "District": "Mtwara District","geometry": null },
        { "region": "Mtwara Region", "District": "Mtwara Municipal","geometry": null },
        { "region": "Mtwara Region", "District": "Nanyumbu District","geometry": null },
        { "region": "Mtwara Region", "District": "Newala District","geometry": null },
        { "region": "Mtwara Region", "District": "Tandahimba District","geometry": null },
        { "region": "Mwanza Region", "District": "Ilemela Municipal","geometry": null },
        { "region": "Mwanza Region", "District": "Kwimba District","geometry": null },
        { "region": "Mwanza Region", "District": "Magu District","geometry": null },
        { "region": "Mwanza Region", "District": "Misungwi District","geometry": null },
        { "region": "Mwanza Region", "District": "Nyamagana Municipal","geometry": null },
        { "region": "Mwanza Region", "District": "Sengerema District","geometry": null },
        { "region": "Mwanza Region", "District": "Ukerewe District","geometry": null },
        { "region": "Njombe Region", "District": "Ludewa District","geometry": null },
        { "region": "Njombe Region", "District": "Makambako Town","geometry": null },
        { "region": "Njombe Region", "District": "Makete District","geometry": null },
        { "region": "Njombe Region", "District": "Njombe District","geometry": null },
        { "region": "Njombe Region", "District": "Njombe Town","geometry": null },
        { "region": "Njombe Region", "District": "Wanging'ombe District","geometry": null },
        { "region": "Pwani Region", "District": "Bagamoyo District","geometry": null },
        { "region": "Pwani Region", "District": "Kibaha District","geometry": null },
        { "region": "Pwani Region", "District": "Kibaha Town","geometry": null },
        { "region": "Pwani Region", "District": "Kisarawe District","geometry": null },
        { "region": "Pwani Region", "District": "Mafia District","geometry": null },
        { "region": "Pwani Region", "District": "Mkuranga District","geometry": null },
        { "region": "Pwani Region", "District": "Rufiji District","geometry": null },
        { "region": "Rukwa Region", "District": "Kalambo District","geometry": null },
        { "region": "Rukwa Region", "District": "Nkasi District","geometry": null },
        { "region": "Rukwa Region", "District": "Sumbawanga District","geometry": null },
        { "region": "Rukwa Region", "District": "Sumbawanga Municipal","geometry": null },
        { "region": "Ruvuma Region", "District": "Mbinga District","geometry": null },
        { "region": "Ruvuma Region", "District": "Songea District","geometry": null },
        { "region": "Ruvuma Region", "District": "Songea Municipal","geometry": null },
        { "region": "Ruvuma Region", "District": "Tunduru District","geometry": null },
        { "region": "Ruvuma Region", "District": "Namtumbo District","geometry": null },
        { "region": "Ruvuma Region", "District": "Nyasa District","geometry": null },
        { "region": "Shinyanga Region", "District": "Kahama Town","geometry": null },
        { "region": "Shinyanga Region", "District": "Kahama District","geometry": null },
        { "region": "Shinyanga Region", "District": "Kishapu District","geometry": null },
        { "region": "Shinyanga Region", "District": "Shinyanga District","geometry": null },
        { "region": "Shinyanga Region", "District": "Shinyanga Municipal","geometry": null },
        { "region": "Simiyu Region", "District": "Bariadi District","geometry": null },
        { "region": "Simiyu Region", "District": "Busega District","geometry": null },
        { "region": "Simiyu Region", "District": "Itilima District","geometry": null },
        { "region": "Simiyu Region", "District": "Maswa District","geometry": null },
        { "region": "Simiyu Region", "District": "Meatu District","geometry": null },
        { "region": "Singida Region", "District": "Ikungi District","geometry": null },
        { "region": "Singida Region", "District": "Iramba District","geometry": null },
        { "region": "Singida Region", "District": "Manyoni District","geometry": null },
        { "region": "Singida Region", "District": "Mkalama District","geometry": null },
        { "region": "Singida Region", "District": "Singida District","geometry": null },
        { "region": "Singida Region", "District": "Singida Municipal","geometry": null },
        { "region": "Songwe Region", "District": "Ileje District","geometry": null },
        { "region": "Songwe Region", "District": "Mbozi District","geometry": null },
        { "region": "Songwe Region", "District": "Momba District","geometry": null },
        { "region": "Songwe Region", "District": "Songwe District","geometry": null },
        { "region": "Tabora Region", "District": "Igunga District","geometry": null },
        { "region": "Tabora Region", "District": "Kaliua District","geometry": null },
        { "region": "Tabora Region", "District": "Nzega District","geometry": null },
        { "region": "Tabora Region", "District": "Sikonge District","geometry": null },
        { "region": "Tabora Region", "District": "Tabora Municipal","geometry": null },
        { "region": "Tabora Region", "District": "Urambo District","geometry": null },
        { "region": "Tabora Region", "District": "Uyui District","geometry": null },
        { "region": "Tanga Region", "District": "Handeni District","geometry": null },
        { "region": "Tanga Region", "District": "Handeni Town","geometry": null },
        { "region": "Tanga Region", "District": "Kilindi District","geometry": null },
        { "region": "Tanga Region", "District": "Korogwe Town","geometry": null },
        { "region": "Tanga Region", "District": "Korogwe District","geometry": null },
        { "region": "Tanga Region", "District": "Lushoto District","geometry": null },
        { "region": "Tanga Region", "District": "Muheza District","geometry": null },
        { "region": "Tanga Region", "District": "Mkinga District","geometry": null },
        { "region": "Tanga Region", "District": "Pangani District","geometry": null },
        { "region": "Tanga Region", "District": "Tanga City","geometry": null },
        { "region": "Unguja Kaskazini Region", "District": "Kaskazini A District","geometry": null },
        { "region": "Unguja Kaskazini Region", "District": "Kaskazini B District","geometry": null },
        { "region": "Pemba Kusini Region", "District": "Micheweni District","geometry": null },
        { "region": "Pemba Kusini Region", "District": "Wete District","geometry": null },
        { "region": "Unguja Mjini Magharibi Region", "District": "Unguja Magharibi District","geometry": null },
        {  "region": "Unguja Mjini Magharibi Region", "District": "Unguja Mjini District","geometry": null }
        ]
}