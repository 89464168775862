import { Injectable } from '@angular/core';
import {Observable,of as ObservableOf} from 'rxjs';
import {HttpClient } from '@angular/common/http';
import{map} from 'rxjs/operators';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  constructor(private http:HttpClient,private sharedService:SharedService) { }

  getTechnicians(filters:any):Observable<any>{
    // {search:'',gender:'',learned:'',region:'',district:'',ward:'',per_page:''}
    let url = `${this.sharedService.api_url}/technicians?page=${filters.page}`;
        url += `&search=${filters.search}&gender=${filters.gender}&learned=${filters.learned}`;
        url += `&region=${filters.region}&district=${filters.district}&ward=${filters.ward}&per_page=${filters.per_page}`
        return this.http.get(url);
  }
  getTechnicianInfo(id:any):Observable<any>{
    return this.http.get(`${this.sharedService.api_url}/technicians/${id}`);
  }
  updateTechnicianInfo(technician:any,data:any):Observable<any>{
    return this.http.put(`${this.sharedService.api_url}/technicians/${technician}`,data);
  }
  updateMenuAccess(technician:any,data:any):Observable<any>{
    return this.http.put(`${this.sharedService.api_url}/technicians/menuaccess/${technician}`,data);
  }
  deleteTechnician(id:any):Observable<any>{
    return this.http.delete(`${this.sharedService.api_url}/technicians/${id}`);
  }
  //sms
  sendBulkSMS(data){
    return this.http.post(`${this.sharedService.api_url}/sms/bulk`,data);
  }
}
