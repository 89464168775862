import { Injectable } from '@angular/core';
import { REGIONDATA } from '../pages/data/locations/regions';
import { DISTRICTDATA } from '../pages/data/locations/districts';
import { WARDSDATA } from '../pages/data/locations/wards';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  private RegionData:any = REGIONDATA;
  private DistrictData:any = DISTRICTDATA;
  private WardsData:any = WARDSDATA;

  constructor() { }

  getRegions(){
    return this.RegionData.regions;
  }
  getDistricts(){
    return this.DistrictData.districts;
  }
  getWards(){
    return this.WardsData.wards;
  }
  getDistrictsByRegion(region:string){
    region += ' region';
    return this.DistrictData.districts.filter(each=>each.region.toLowerCase() === region.toLowerCase());
  }
  getWardsByDistricts(district:string){
    return this.WardsData.wards.filter(each=>each.District.toLowerCase() === district.toLowerCase());
  }
}