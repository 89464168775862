<div class="row">
  <div class="col-md-12 col-lg-6 col-xxxl-3">
    <nb-card>
      <nb-card-body>
        <pre>
          <p>{{message}}{{menu}}</p>
        </pre>  
        <input class="ussd-input" *ngIf="show_text_input" id="input">      
      </nb-card-body>
      <nb-card-footer>
        <button nbButton size="tiny" (click)="dismiss()" *ngIf="show_cancel_btn">Cancel</button>
        <button nbButton size="tiny" (click)="submit()" class="pull-right" *ngIf="show_submit_btn">Send</button>
      </nb-card-footer>
    </nb-card>
  </div>
</div>