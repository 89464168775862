import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn:boolean;
  constructor() {
     let user = this.getUser();
     if(user){
       this.loggedIn = true;
     }else{
       this.loggedIn = false;
     }
   }
  setLoggedIn(val:boolean){
    this.loggedIn = val;
  }
  isLoggedIn(){
    return this.loggedIn;
  }
  storeUser(user:any){
     if(localStorage){
       localStorage.setItem('user',user);
     }
  }
  setRemember(user){
    if(localStorage){
      localStorage.setItem('auth',user);
    }
  }
  clearRemember(){
    if(localStorage){
      localStorage.removeItem('auth');
    }
  }
  getRemember():any{
    if(localStorage){
       let auth = localStorage.getItem('auth');
       return auth;
    }
  }
  getUser():any{
    if(localStorage){
       let user = localStorage.getItem('user');
       return user;
    }
  }
  clearUser(){
    if(localStorage){
      localStorage.removeItem('user');
    }
  }
}