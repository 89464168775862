import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse } from '@angular/common/http';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})

export class UserService { 

  constructor( private http:HttpClient,private sharedService:SharedService) {}
  
  logIn(user):Observable<HttpResponse<any>>{
    return this.http.post(`${this.sharedService.api_url}/staff/auth/login`,user,{observe: 'response',withCredentials:true});    
  }
  logOut(data):Observable<any>{
    return this.http.post(`${this.sharedService.api_url}/users/logout`,data);    
  }
  // fetchUser(email:string):Observable<any>{
  //   return this.http.post(`${this.sharedService.api_url}/users/fetchUser?email=${email}`,{});
  // }
  // recoverPwd(data):Observable<any>{
  //   return this.http.post(`${this.sharedService.api_url}/users/recoverPwd`,data);    
  // }
}